import React, { useState } from 'react';

import { renderRichText } from "gatsby-source-contentful/rich-text"
import options from '../utils/getRichTextOptions';

import "../theme/layout.scss"
import CustomFooter from '../components/CustomFooter';
import GdprCookies from '../components/GdprCookies';
import HeaderLayout from '../components/layout/HeaderLayout';
import { graphql } from 'gatsby';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';

import ModalSuscribe from '../components/layout/ModalSuscribre';
import parseDate from '../utils/parseDate';


const Flashes = ({data}) => {

    console.log(data)


    const listaDePublicaciones: unknown[] = [];
    listaDePublicaciones.push(...data.flashEconomico.edges);
    listaDePublicaciones.push(...data.flashEmisora.edges);
    listaDePublicaciones.push(...data.flashTecnico.edges);

    var listaSort: any[] = listaDePublicaciones.sort(function (a: any, b: any) {
        return +new Date(b.createdAt).getTime() - +new Date(a.createdAt).getTime();
    });


    //Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const todosPerPage = 10;
    const totalCards = listaSort;
    const indexOfLastTodo = (currentPage * todosPerPage);
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const cards = totalCards.slice(indexOfFirstTodo,indexOfLastTodo);
    const totalPages = Math.ceil(totalCards.length / todosPerPage);



    //functions modal suscribe
    const [open, setOpen] = useState(false);
    function openSuscribe(){
        setOpen(true);
    }
    function closeSuscribe(){
        setOpen(false);
    }

    return (
        <>
            <HeaderLayout mb={true} suscribe={true} openModal={openSuscribe}/>
            <div className='wrap-content'>
                <div className='header--section'>
                    <h2 className='title--section'>Flashes</h2>
                </div>
                {cards && cards.map(( node, index ) => {
                    let prefix_slug = "";
                    if(node.node.sys.contentType.sys.id == "flashEconomico"){
                        prefix_slug = "/economico/";
                    }
                    if(node.node.sys.contentType.sys.id == "flashEmisora"){
                        prefix_slug = "/renta-variable/";
                    }
                    if(node.node.sys.contentType.sys.id == "flashTecnico"){
                        prefix_slug = "/tecnico/";
                    }


                    return (

                        
                        <div key={index} className="item">
                            <p className="date">{parseDate(node.node.createdAt)}</p>
                            <h2 className="title"><b>{node.node.titulo}</b></h2>
                            <div className="resume-content">
                                {
                                node.node.resumen?.raw ? 
                                renderRichText(node.node.resumen,options)
                                : 
                                node.node.resumen
                                }
                            </div>
                            <div className="item--footer">
                                <p className="autor">{}</p>
                                <a href={prefix_slug+node.node.slug} >
                                    <div className="link">Ver más</div>
                                </a>
                            </div>
                        </div>
                    )
                
                })}

                {/* <div className='loader-scroll'>
                    <img src={loader} alt="" />
                </div> */}
            <div className='wrap-pagination'>
            <div className='pagination'>
              <FirstPageIcon fontSize='small' onClick={()=>setCurrentPage(1)}/>
              <NavigateBeforeIcon fontSize='small' onClick={()=>{
                if(currentPage > 1){
                  setCurrentPage(currentPage-1)
                }
                console.log("index of", indexOfLastTodo)
                console.log("index first", indexOfFirstTodo)
              }}/>
              <div className='text'>{currentPage}</div>
              <div className='text de'>de</div>
              <div className='text'>{totalPages}</div>
              <NavigateNextIcon fontSize='small' onClick={()=>{
                if(currentPage < totalPages ){
                  setCurrentPage(currentPage+1);
                }
                console.log("index of", indexOfLastTodo)
                console.log("index first", indexOfFirstTodo)
              }}/>
              <LastPageIcon fontSize='small' onClick={()=>setCurrentPage(totalPages)}/>
            </div>
          </div>
            </div>
            <CustomFooter openModal={openSuscribe}/>
            <GdprCookies />
            {
          open && <ModalSuscribe closeModal={closeSuscribe}/>
        }


        </>
    )
}

export default Flashes


export const pageQuery = graphql`
query MyQueryFlashes{
    
    flashEconomico: allContentfulFlashEconomico(
        sort: { fields: createdAt, order: DESC }
      ) {
        edges {
            node {
              createdAt
              titulo
              resumen
              autor {
                nombre
              }
              slug
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
        }
      }

      flashEmisora: allContentfulFlashEmisora(
        sort: { fields: createdAt, order: DESC }
      ) {
        edges {
            node {
              createdAt
              titulo
              resumen
              autor {
                nombre
              }
              slug
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
        }
      }

      flashTecnico: allContentfulFlashTecnico(
        sort: { fields: createdAt, order: DESC }
      ) {
        edges {
            node {
              createdAt
              titulo
              resumen
              autor {
                nombre
              }
              slug
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
        }
      }
      

  }
`;